<template>
  <button :disabled="props.disabled" class="transition-all cursor-pointer">
    <span v-if="!loading" class="flex items-center justify-center flex-nowrap">
      <Icon
        v-if="props.icon.name !== ''"
        :icon="props.icon.name"
        :class="props.icon.class"
        :size="props.icon.size"
        :fill="props.icon.fill"
      />
      <span
        class="text-nowrap first-letter:capitalize"
        :class="{
          '-order-1': props.icon.position == 'right',
        }"
        >{{ props.label }}</span
      >
    </span>
    <span v-else class="flex flex-row items-center justify-center gap-x-4">
      Loading <GeneralLoader class="max-w-4 max-h-4 text-dark-500" />
    </span>
  </button>
</template>
<script setup>
import Icon from '@/Redesign/Components/Assets/Icon.vue';
import GeneralLoader from '@/Redesign/Components/Loaders/GeneralLoader.vue';

const props = defineProps({
  label: {
    type: String,
    default: '',
  },
  icon: {
    type: Object,
    default: () => ({
      name: '',
      class: 'mr-2',
      size: 24,
      fill: false,
      position: 'left',
    }),
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  loading: {
    type: Boolean,
    default: false,
  },
});
</script>
<style scoped></style>
