<template>
  <div class="flex justify-center min-w-9 min-h-9">
    <svg viewBox="25 25 50 50" class="fill-none stroke-primary-500 stroke-[3]">
      <circle r="20" cy="50" cx="50"></circle>
    </svg>
  </div>
</template>
<style scoped>
svg {
  width: 36px;
  transform-origin: center;
  animation: rotate 2s linear infinite;
}

circle {
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  stroke-linecap: round;
  animation: dash 1.5s ease-in-out infinite;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 90, 200;
    stroke-dashoffset: -35px;
  }

  100% {
    stroke-dashoffset: -125px;
  }
}
</style>
